<template>
  <div class="bigBox" ref="fullscreen">
    <div class="header">
      <!-- logo -->
      <img src="@/assets/logo.png" alt="" />
      <!-- 试卷等级 -->
      <span v-if="this.level == 'KET'">
        A2 Key English Test,Cambridge English (LWR)
      </span>
      <span v-else-if="this.level == 'PET'">
        B1 Key English Test,Cambridge English (LWR)
      </span>
      <span v-else class="level"
        >International ESOL {{ level }}, IESOL_(LWR)
      </span>
      <!-- 计时器 -->
      <div class="btn">
        <img src="@/assets/clock.png" alt="" /><span
          >{{ hr }}:{{ min }}:{{ sec }}</span
        >
      </div>
    </div>
    <div class="body">
      <!-- 左边的part分栏模块 -->
      <div class="left">
        <div class="listening">
          <span>Listening</span>
          <ul>
            <li
              v-for="(part, i) in listening"
              :key="i"
              @click="listenPart(part, i)"
            >
              <img
                src="@/assets/white.png"
                alt=""
                v-show="flag == part ? false : true"
              /><img
                src="@/assets/red.png"
                alt=""
                v-show="flag == part ? true : false"
              /><span
                :class="flag == part ? 'active' : ''"
                ref="listen"
                :style="
                  active[i]
                    ? ''
                    : 'background-color:#a6a6a6;color:#fff;cursor:no-drop ;'
                "
                >{{ part }}
              </span>
              <div v-show="flag == part ? true : false"></div>
            </li>
          </ul>
        </div>
        <div class="reading">
          <span>Reading</span>
          <ul>
            <li
              v-for="(part, i) in reading"
              :key="i"
              @click="readPart(part, i)"
            >
              <img
                src="@/assets/white.png"
                alt=""
                v-show="flag == part ? false : true"
              /><img
                src="@/assets/red.png"
                alt=""
                v-show="flag == part ? true : false"
              /><span
                :class="flag == part ? 'active' : ''"
                ref="read"
                :style="
                  active[4]
                    ? ''
                    : 'background-color:#a6a6a6;color:#fff;cursor:no-drop ;'
                "
                >{{ part }}</span
              >
              <div v-show="flag == part ? true : false"></div>
            </li>
          </ul>
        </div>
        <div class="writing">
          <span>Writing</span>
          <ul>
            <li
              v-for="(part, i) in writing"
              :key="i"
              @click="writePart(part, i)"
            >
              <img
                src="@/assets/white.png"
                alt=""
                v-show="flag == part ? false : true"
              /><img
                src="@/assets/red.png"
                alt=""
                v-show="flag == part ? true : false"
              /><span
                :class="flag == part ? 'active' : ''"
                ref="write"
                :style="
                  active[4]
                    ? ''
                    : 'background-color:#a6a6a6;color:#fff;cursor:no-drop ;'
                "
                >{{ part }}</span
              >
              <div v-show="flag == part ? true : false"></div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 右边的答题模块 -->
      <div class="right">
        <!-- 上方的音频盒子 -->
        <div class="top">
          <div class="vedio">
            <audio :src="listerUrl" autoplay ref="audio"></audio>
            <i
              class="el-icon-headset"
              v-if="
                !active[0] ||
                !active[1] ||
                !active[2] ||
                !active[3] ||
                !active[4]
              "
            ></i>
            <el-progress
              :percentage="progress1"
              color="#f03636"
              :show-text="false"
              v-if="active[0] && !active[1]"
            ></el-progress>
            <el-progress
              :percentage="progress2"
              color="#f03636"
              :show-text="false"
              v-if="active[1] && !active[2]"
            ></el-progress>
            <el-progress
              :percentage="progress3"
              color="#f03636"
              :show-text="false"
              v-if="active[2] && !active[3]"
            ></el-progress>
            <el-progress
              :percentage="progress4"
              color="#f03636"
              :show-text="false"
              v-if="active[3] && !active[4]"
            ></el-progress>
          </div>
          <div class="btn">
            <!--  -->
            <el-button
              @click="previouis"
              v-show="flag != 'Listening part 1' && active[4]"
              >Previous</el-button
            >
            <!--  -->
            <el-button
              @click="next"
              v-show="flag != 'Writing part 2' && active[4]"
              >Next</el-button
            >
          </div>
        </div>
        <!-- 下方的答题盒子 -->
        <div class="down" v-if="level == 'A1'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <!-- <PaperTwo v-show="flag == 'Listening part 2'"/> -->
            <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <PaperFive v-show="flag == 'Reading part 1'" />
          <DargTwo v-show="flag == 'Reading part 2'" />
          <PaperEight v-show="flag == 'Reading part 3'" />
          <NinePicture v-show="flag == 'Reading part 4'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
        </div>
        <div class="down" v-if="level == 'A2'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <!-- <PaperTwo v-show="flag == 'Listening part 2'"/> -->
          <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <PaperFive v-show="flag == 'Reading part 1'" />
          <DargOne v-show="flag == 'Reading part 2'" />
          <PaperEight v-show="flag == 'Reading part 3'" />
          <PaperRed4A2 v-show="flag == 'Reading part 4'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
        </div>
        <div class="down" v-if="level == 'B1'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <!-- <PaperTwo v-show="flag == 'Listening part 2'"/> -->
          <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <PaperFive v-show="flag == 'Reading part 1'" />
          <DargOne v-show="flag == 'Reading part 2'" />
          <PaperEight v-show="flag == 'Reading part 3'" />
          <PaperSeven v-show="flag == 'Reading part 4'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
        </div>
        <div class="down" v-if="level == 'B2'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <!-- <PaperTwo v-show="flag == 'Listening part 2'"/> -->
          <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <PaperSix v-show="flag == 'Reading part 1'" />
          <DargOne v-show="flag == 'Reading part 2'" />
          <PaperEight v-show="flag == 'Reading part 3'" />
          <PaperSeven v-show="flag == 'Reading part 4'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
        </div>
        <div class="down" v-if="level == 'C1'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <!-- <PaperTwo v-show="flag == 'Listening part 2'"/> -->
          <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <TorF v-show="flag == 'Reading part 1'" />
          <DargH v-show="flag == 'Reading part 2'" />
          <PaperEight v-show="flag == 'Reading part 3'" />
          <PaperSeven v-show="flag == 'Reading part 4'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
        </div>
        <!-- 考试登记为KET -->
        <div class="down" v-if="level == 'KET'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <PaperListen2New v-show="flag == 'Listening part 2'" />
          <PaperListen3And4New v-show="flag == 'Listening part 3&4'"></PaperListen3And4New>
          <!-- <PaperListen3 v-show="flag == 'Listening part 3'" /> -->
          <PaperListen5New v-show="flag == 'Listening part 5'"></PaperListen5New>
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriter2 v-show="flag == 'Writing part 2'" />
          <PaperReading1New v-show="flag == 'Reading part 1'" />
          <PaperReading2New v-show="flag == 'Reading part 2'" />
          <PaperReading3And4New v-show="flag == 'Reading part 3&4'" />
          <PaperReading5New v-show="flag == 'Reading part 5'" />
          <!-- <PaperListen4 v-show="flag == 'Listening part 4'" /> -->
        </div>
        <!-- 考试登记为PET -->
        <div class="down" v-if="level == 'PET'">
          <PaperOne v-show="flag == 'Listening part 1'" />
          <PaperListen2 v-show="flag == 'Listening part 2'" />
          <PaperListen3 v-show="flag == 'Listening part 3'" />
          <PaperListen4 v-show="flag == 'Listening part 4'" />
          <PaperReading1PET v-show="flag == 'Reading part1&2&3'"/>
          <PaperReading2PET v-show="flag == 'Reading part 4'"/>
          <PaperReading3PET v-show="flag == 'Reading part 5'"/>
          <PaperReading4PET v-show="flag == 'Reading part 6'" />
          <PaperFour v-show="flag == 'Writing part 1'" />
          <PaperWriting2PET v-show="flag == 'Writing part 2'" />
        </div>
      </div>
    </div>
    <div class="foot">
      <span>All rights reserved</span>
      <!--  -->
      <el-button @click="dialogVisible = true">End Exam</el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="30%"
      style="margin-top: 100px"
    >
      <span class="text">Are you sure you want to submit your paper?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit" class="yes">Yes</el-button>
        <el-button type="primary" @click="dialogVisible = false" class="no"
          >No</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleTwo"
      :show-close="false"
      width="30%"
      style="margin-top: 100px"
    >
      <span class="text"
        >The answer has been submitted and the exam result will be sent to your
        email within three working days. You can also check it on the Exam
        History page.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="complete" class="yes">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleError"
      :show-close="false"
      width="30%"
      style="margin-top: 100px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="text"
        >Exam time has ended but automatic submission has failed, <br />please
        check your network and click the submit button.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit" class="yes">Submit</el-button>
      </span>
    </el-dialog>
    <!-- 提交失败 -->
    <el-dialog
      :visible.sync="dialogVisibleErrorS"
      :show-close="false"
      width="460px"
      style="margin-top: 100px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="text" style="word-wrap:break-word;"
        >Test paper submission failed due to account status exception.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="spError" class="yes">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaperOne from "./PaperOne";
import PaperTwo from "./PaperTwo";
import PaperThree from "./PaperThree";
import PaperFour from "./PaperFour";
import PaperWriter2 from "./PaperWriter2";
import PaperFive from "./PaperFive";
import PaperSix from "./PaperSix";
import PaperSeven from "./PaperSeven";
import PaperEight from "./PaperEight";
import PaperListen4 from "./PaperListen4";
import PaperListen2 from "./PaperListen2";
import PaperListen3 from "./PaperListen3";
import DargOne from "./DargOne";
import DargTwo from "./DargTwo";
import DargH from "./DargH";
import TorF from "./TorF";
import NinePicture from "./ninePictureDrag";
import PaperRed4A2 from "./PaperRed4A2";
import PaperListen2New from './PaperListen2New'
import PaperListen3And4New from "./PaperListen3&4New";
import PaperListen5New from "./PaperListen5New";
import PaperReading1New from "./PaperReading1New"
import PaperReading2New from './PaperReading2New'
import PaperReading3And4New from './PaperReading3&4New'
import PaperReading5New from './PaperReading5New'
import PaperReading1PET from './PaperReading1PET'
import PaperReading2PET from './PaperReading2PET'
import PaperReading3PET from './PaperReading3PET'
import PaperReading4PET from './PaperReading4PET'
import PaperWriting2PET from './PaperWriting2PET'
export default {
  name: "ExamPaper",
  components: {
    PaperOne,
    PaperTwo,
    PaperThree,
    PaperFour,
    PaperFive,
    PaperSix,
    PaperSeven,
    PaperEight,
    PaperWriter2,
    PaperListen4,
    PaperListen3,
    PaperListen2,
    DargTwo,
    DargOne,
    NinePicture,
    TorF,
    DargH,
    PaperRed4A2,
    PaperListen2New,
    PaperListen3And4New,
    PaperListen5New,
    PaperReading1New,
    PaperReading2New,
    PaperReading3And4New,
    PaperReading5New,
    PaperReading1PET,
    PaperReading2PET,
    PaperReading3PET,
    PaperReading4PET,
    PaperWriting2PET
  },
  data() {
    return {
      listening: [
        "Listening part 1",
        "Listening part 2",
        "Listening part 3",
        "Listening part 4",
      ],
      reading: [
        "Reading part 1",
        "Reading part 2",
        "Reading part 3",
        "Reading part 4",
      ],
      writing: ["Writing part 1", "Writing part 2"],
      // 第一题有图片的情况
      listeningP1HaveOptionsImg: [],
      //   控制part选中状态
      flag: "Listening part 1",
      // 控制考试结束弹窗
      dialogVisible: false,
      dialogVisibleTwo: false,
      dialogVisibleErrorS: false,
      // 音频地址
      listerUrl: "",
      hr: "",
      // 判断剩余时间
      hr1: 0,
      min: "",
      // 判断剩余时间
      min1: 0,
      sec: "",
      // 判断剩余时间
      sec1: 0,
      end: 0,
      // 提交答案的数据
      totalAnswer: {
        userId: "",
        key: "",
        lp1AnswerList: [],
        lp2AnswerList: [],
        lp3AnswerList: [],
        lp4AnswerList: [],
        rp1AnswerList: [],
        rp2AnswerList: [],
        rp3AnswerList: [],
        rp4AnswerList: [],
        wp1Text: "",
        wp2Text: "",
        duration: "",
      },
      // 控制音频播放完才能点击
      active: [true, false, false, false, false],
      // 控制音频时间
      listeningP1Time: 0,
      listeningP2Time: 0,
      listeningP3Time: 0,
      listeningP4Time: 0,
      listeningTime: 0,
      // 进度条1
      progress1: 0,
      // 进度条2
      progress2: 0,
      progress3: 0,
      progress4: 0,
      totalTime: 0,
      timer: "",
      //  提交失败的弹窗
      dialogVisibleError: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.updateRecord);
    // this.getInfo()
    // const audio = this.$refs.audio
    // audio.play()
    // console.log(this.$refs.audio);
    // console.log(this.list);
    this.totalAnswer.key = this.key;
    this.totalAnswer.userId = sessionStorage.getItem("userId");
    this.$refs.listen[0].style =
      "background-color: #f03636;color: #fff; border: none;";

    this.getPaperInfo();
    if (this.level == "A1" || this.level == "A2") {
      this.end = Date.parse(new Date()) + 6000000;
      // this.end = Date.parse(new Date()) + 60000;
      this.totalTime = 6000000;
    } else if (this.level == "B1" || this.level == "B2") {
      this.end = Date.parse(new Date()) + 9600000;
      // this.end = Date.parse(new Date()) + 60000;
      this.totalTime = 9600000;
    } else if (this.level == "C1" || this.level == "C2") {
      this.end = Date.parse(new Date()) + 11400000;
      // this.end = Date.parse(new Date()) + 60000;
      this.totalTime = 11400000;
    }else if (this.level == "KET") {
      this.end = Date.parse(new Date()) + 5400000;
      // this.end = Date.parse(new Date()) + 60000;
      this.totalTime = 5400000;
    }else if(this.level == "PET"){
      this.end = Date.parse(new Date()) + 7200000;
      this.totalTime = 7200000;
    }
    this.timer = setInterval(() => {
      this.countdown();
    }, 1000);

    // 倒计时
    // this.countdown();
    // document.documentElement.requestFullscreen();
    /*     document.addEventListener('keydown', function (event) {
  if (event.code === 'Escape' || event.code === 'F11') {
    event.preventDefault();

  }
});
  */
    //  // 监听keydown和keyup事件
    // window.addEventListener('keydown', this.disableShortcut);
    // window.addEventListener('keyup', this.disableShortcut);
  },
  methods: {
    spError() {
      this.$router.replace({ path: "/home" });
    },
    updateRecord() {
      fetch("http://43.138.11.55:8085/user/checkansweroffline", {
        method: "POST",
        body: JSON.stringify(this.totalAnswer),
        headers: { "Content-Type": "application/json" },
        keepalive: true,
      });
    },

    handleBeforeUnload(e) {
      e.preventDefault();
      e.returnValue =
        "Refreshing may result in exam failure. Are you sure you want to refresh?";
      const confirmationMessage =
        "Refreshing may result in exam failure. Are you sure you want to refresh?";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    // 权限
    // 获取用户信息
    // async getInfo() {
    //   try {
    //     let result = await this.$API.getUserInfo();
    //     if (result.status == "success") {
    //      return;
    //     }else{
    //       if(result.data.errCode == "100001"){
    //         this.$router.replace({path:'/login'})
    //       }
    //     }
    //   } catch (error) {
    //      this.$message({
    //       message: 'Please try again later for network abnormalities',
    //     });
    //   }
    // },
    // 选中part时的回调
    listenPart(part, i) {
      // 判断音频是否到时间
      if (!this.active[i]) {
        return;
      }
      this.flag = part;
      // 点完之后持续变红

      this.$refs.listen[i].style =
        "background-color: #f03636;color: #fff; border: none;";
    },
    // 选中part时的回调
    readPart(part, i) {
      // 判断音频是否到时间
      if (!this.active[4]) {
        return;
      }
      this.flag = part;
      // 点完之后持续变红
      this.$refs.read[i].style =
        "background-color: #f03636;color: #fff; border: none;";
    },
    // 选中part时的回调
    writePart(part, i) {
      // 判断音频是否到时间
      if (!this.active[4]) {
        return;
      }
      this.flag = part;
      // 点完之后持续变红
      this.$refs.write[i].style =
        "background-color: #f03636;color: #fff; border: none;";
    },
    // 点击结束考试的回调
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          alert("考试结束");
        })
        .catch((_) => {});
    },
    // 上一题的按钮
    previouis() {
      // console.log(this.list[this.list.indexOf(this.flag) - 1].slice(0, 1));
      let newPart = this.list[this.list.indexOf(this.flag) - 1];
      // 获取到当前part的上一个part值
      this.flag = newPart;
      // 判断值开头的第一个元素决定用什么方法
      if (newPart.slice(0, 1) == "L") {
        this.$refs.listen[0].style =
          "background-color: #f03636;color: #fff; border: none;";
        this.$refs.listen[this.listening.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      } else if (newPart.slice(0, 1) == "R") {
        // 点完之后持续变红
        this.$refs.read[this.reading.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      } else if (newPart.slice(0, 1) == "W") {
        // 点完之后持续变红
        this.$refs.write[this.writing.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      }
    },
    // 下一题按钮
    next() {
      // console.log(this.list[this.list.indexOf(this.flag) + 1].slice(0, 1));
      let newPart = this.list[this.list.indexOf(this.flag) + 1];
      // 获取到当前part的上一个part值
      this.flag = newPart;
      // 判断值开头的第一个元素决定用什么方法
      if (newPart.slice(0, 1) == "L") {
        this.$refs.listen[0].style =
          "background-color: #f03636;color: #fff; border: none;";
        this.$refs.listen[this.listening.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      } else if (newPart.slice(0, 1) == "R") {
        // 点完之后持续变红
        this.$refs.read[this.reading.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      } else if (newPart.slice(0, 1) == "W") {
        // 点完之后持续变红
        this.$refs.write[this.writing.indexOf(newPart)].style =
          "background-color: #f03636;color: #fff; border: none;";
      }
    },
    // 进度条小耳机图标
    // format() {
    //     return this.progress1 === 100 ? '满' : `${Math.ceil(this.progress1)}%`;
    //   },
    async getPaperInfo() {
      const result = this.paperDate;
      this.listerUrl = result.listeningUrl;
      this.listeningP1Time = result.listeningP1Time;
      this.listeningP2Time = result.listeningP2Time;
      this.listeningP3Time = result.listeningP3Time;
      this.listeningP4Time = result.listeningP4Time;
      // console.log(result);
      if(this.level=='KET'){
      this.listening = ["Listening part 1","Listening part 2","Listening part 3&4","Listening part 5",]
      this.reading = ["Reading part 1","Reading part 2","Reading part 3&4", "Reading part 5",]
      }
      if(this.level=='PET'){
        this.reading = ["Reading part1&2&3","Reading part 4","Reading part 5", "Reading part 6",]
      }
      console.log(result.data);
    },
    // 倒计时
    countdown() {
      const now = Date.parse(new Date());
      const msec = this.end - now;
      // console.log(this.end);

      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      if (msec >= 1000) {
        //倒计时结束关闭订单
        // console.log(111);

        let hr1 = parseInt(((this.totalTime - msec) / 1000 / 60 / 60) % 24);
        hr1 = hr1 > 9 ? hr1 : "0" + hr1;
        let min1 = parseInt(((this.totalTime - msec) / 1000 / 60) % 60);
        min1 = min1 > 9 ? min1 : "0" + min1;
        let sec1 = parseInt(((this.totalTime - msec) / 1000) % 60);
        sec1 = sec1 > 9 ? sec1 : "0" + sec1;
        this.totalAnswer.duration = hr1 + ":" + min1 + ":" + sec1;
        this.hr1 = parseInt(hr1);
        this.min1 = parseInt(min1);
        this.sec1 = parseInt(sec1);

        // console.log(this.hr1,this.min1,this.sec1);
        this.listeningTime += 1;
        // console.log(this.listeningTime);
      } else {
        this.hr = "--";
        this.min = "--";
        this.sec = "--";
        if (this.level == "A1" || this.level == "A2") {
          this.totalAnswer.duration = "01:40:00";
        } else if (this.level == "B1" || this.level == "B2") {
          this.totalAnswer.duration = "02:40:00";
        } else if (this.level == "C1" || this.level == "C2") {
          this.totalAnswer.duration = "03:10:00";
        }else if (this.level == "KET") {
          this.totalAnswer.duration = "01:30:00";
        }else if (this.level == "PET") {
          this.totalAnswer.duration = "02:00:00";
        }
        clearInterval(this.timer);
        this.submit("err");
        return;
      }
    },
    // 提交按钮
    async submit(err) {
      this.dialogVisible = false;
      try {
        const result = await this.$API.putPaper(this.totalAnswer);
        // console.log(result);
        if (result.status == "success") {
          this.dialogVisibleTwo = true;
        } else {
          if (result.data.errCode == 100003 || result.data.errCode == 100001) {
            this.dialogVisibleErrorS = true;
            return;
          }
          this.$message({
            message: result.data.errMsg,
            type: "error",
          });
          return;
        }
      } catch (error) {
        if (err == "err") {
          this.dialogVisibleError = true;
        }
        this.$message({
          message: "Please try again later for network abnormalities",
        });
      }
    },
    // 确认提示后跳往首页
    complete() {
      this.$router.replace({ path: "/home" });
    },
  },
  computed: {
    list: function () {
      return [...this.listening, ...this.reading, ...this.writing];
    },
    // 获取vuex数据
    ...mapState({
      level: (state) => state.home.level,
      key: (state) => state.home.key,
      paperDate: (state) => state.home.data,
      answerLisOne: (state) => state.exam.answerLisOne,
      answerLisTwo: (state) => state.exam.answerLisTwo,
      answerLisThree: (state) => state.exam.answerLisThree,
      answerLisFour: (state) => state.exam.answerLisFour,
      answerRedOne: (state) => state.exam.answerRedOne,
      answerRedTwo: (state) => state.exam.answerRedTwo,
      answerRedThree: (state) => state.exam.answerRedThree,
      answerRedFour: (state) => state.exam.answerRedFour,
      answerWitOne: (state) => state.exam.answerWitOne,
      answerWitTwo: (state) => state.exam.answerWitTwo,
    }),
  },
  watch: {
    // 监听数据
    paperDate() {
      this.getPaperInfo();
    },
    key() {
      this.totalAnswer.key = this.key;
    },
    answerLisOne() {
      this.totalAnswer.lp1AnswerList = this.answerLisOne;
    },
    answerLisTwo() {
      this.totalAnswer.lp2AnswerList = this.answerLisTwo;
    },
    answerLisThree() {
      this.totalAnswer.lp3AnswerList = this.answerLisThree;
    },
    answerLisFour() {
      this.totalAnswer.lp4AnswerList = this.answerLisFour;
    },
    answerRedOne() {
      this.totalAnswer.rp1AnswerList = this.answerRedOne;
    },
    answerRedTwo() {
      this.totalAnswer.rp2AnswerList = this.answerRedTwo;
    },
    answerRedThree() {
      this.totalAnswer.rp3AnswerList = this.answerRedThree;
    },
    answerRedFour() {
      this.totalAnswer.rp4AnswerList = this.answerRedFour;
    },
    answerWitOne() {
      this.totalAnswer.wp1Text = this.answerWitOne.replace(/\//g, "\\/");
    },
    answerWitTwo() {
      this.totalAnswer.wp2Text = this.answerWitTwo.replace(/\//g, "\\/");
      //  console.log(this.totalAnswer);
    },
    // 听力时间到了才能点击下一题
    sec() {
      // console.log('====================================');
      // console.log(this.end - Date.parse(new Date()));
      // console.log('====================================');
      // 自动跳到下一题
      if (
        parseInt(this.listeningTime) >= parseInt(this.listeningP1Time) &&
        parseInt(this.listeningTime) <= parseInt(this.listeningP1Time) + 3
      ) {
        this.active[1] = true;
        this.flag = "Listening part 2";
        this.$refs.listen[1].style =
          // "background-color: #f03636;color: #fff; border: none;";
          "background-color: #f03636;color: #fff; border: none;";
      }
      // 自动跳到下一题
      if (
        parseInt(this.listeningTime) >=
          parseInt(this.listeningP2Time) + parseInt(this.listeningP1Time) &&
        parseInt(this.listeningTime) <=
          parseInt(this.listeningP2Time) + parseInt(this.listeningP1Time) + 3
      ) {
        this.active[2] = true;
        if(this.level=='KET'){
            this.flag = "Listening part 3&4";
        }else{
            this.flag = "Listening part 3";
        }
        this.$refs.listen[2].style =
          "background-color: #f03636;color: #fff; border: none;";
      }
      // 自动跳到下一题
      if (
        parseInt(this.listeningTime) >=
          parseInt(this.listeningP3Time) +
            parseInt(this.listeningP2Time) +
            parseInt(this.listeningP1Time) &&
        parseInt(this.listeningTime) <=
          parseInt(this.listeningP3Time) +
            parseInt(this.listeningP2Time) +
            parseInt(this.listeningP1Time) +
            3
      ) {
        this.active[3] = true;
        if(this.level=='KET'){
            this.flag = "Listening part 5";
        }else{
            this.flag = "Listening part 4";
        }
        this.$refs.listen[3].style =
          "background-color: #f03636;color: #fff; border: none;";
      }
      // 自动跳到下一题
      if (
        parseInt(this.listeningTime) >=
          parseInt(this.listeningP4Time) +
            parseInt(this.listeningP3Time) +
            parseInt(this.listeningP2Time) +
            parseInt(this.listeningP1Time) &&
        parseInt(this.listeningTime) <=
          parseInt(this.listeningP4Time) +
            parseInt(this.listeningP3Time) +
            parseInt(this.listeningP2Time) +
            parseInt(this.listeningP1Time) +
            3
      ) {
        this.active[4] = true;
      }
      // console.log(this.active);
      this.progress1 = (this.listeningTime / this.listeningP1Time) * 100;
      this.progress2 =
        ((this.listeningTime - this.listeningP1Time) / this.listeningP2Time) *
        100;
      this.progress3 =
        ((this.listeningTime - this.listeningP1Time - this.listeningP2Time) /
          this.listeningP3Time) *
        100;
      this.progress4 =
        ((this.listeningTime -
          this.listeningP1Time -
          this.listeningP2Time -
          this.listeningP3Time) /
          this.listeningP4Time) *
        100;
    },
  },
  // 销毁之前删除事件
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.updateRecord);
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  width: 1300px;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 203px;
      // height: 72px;
      scale: 1.4;
      margin-top: 25px;
      margin-left: 25px;
    }
    .level {
      margin-top: 50px;
      font-size: 14px;
      font-weight: 700;
    }
    .btn {
      background-color: #f03636;
      display: flex;
      align-items: center;
      margin-right: 50px;
      margin-top: 20px;
      padding: 8px 25px;
      border-radius: 5px;

      img {
        width: 19px;
        height: 19px;
        margin: 0;
        margin-right: 10px;
      }
      span {
        color: #fff;
        font-size: 14px;
      }
    }
  }
  .body {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 3.8px solid #bfbfbf;
    display: flex;
    justify-content: space-around;
    .left {
      width: 17%;
      background-color: #fcfcff;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .listening,
      .reading,
      .writing {
        span {
          font-size: 14px;
          font-weight: 700;
        }
        ul {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          li {
            list-style: none;
            display: flex;
            // align-items: center;
            height: 36px;
            width: 180px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              margin-top: 5px;
            }
            span {
              height: 36px;
              width: 120px;
              border: 1px solid #000 !important;
              font-size: 12px;
              text-align: center;
              line-height: 38px;
              font-weight: normal;
              box-sizing: border-box;
            }
            .active {
              background-color: rgb(51, 51, 51) !important;
              border: none;
              color: #fff;
            }
            div {
              border-width: 18px;
              border-style: solid;
              border-color: transparent transparent transparent rgb(51, 51, 51);
              border-image: initial;
            }
          }
        }
      }
    }
    .right {
      width: 81%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .top {
        box-sizing: border-box;
        height: 80px;
        width: 100%;
        background-color: #fcfcff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .vedio {
          width: 60%;
          height: 100%;
          display: flex;
          justify-content: right;
          align-items: center;
          gap: 10px;
          audio {
            // width: 300px;
            // height: 54px;
            // pointer-events: none;
          }
          .el-progress {
            width: 400px;
          }
        }
        .btn {
          display: flex;
          gap: 20px;
          margin-right: 20px;
          .el-button {
            width: 100px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f03636;
            border: none;
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .down {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        background-color: #fcfcff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      }
    }
  }
  .foot {
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    span {
      width: 50%;
      height: 100%;
      text-align: right;
      line-height: 50px;
    }
    .el-button {
      height: 35px;
      width: 120px;
      margin-right: 50px;
      background-color: #f03636;
      color: #fff;
      border: none;
    }
  }
  .el-dialog {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 100px;
    .text {
      display: inline-block;
      margin-top: -20px;
      height: 60px;
      width: 100%;
      text-align: center;
    }
    .dialog-footer {
      display: flex;
      justify-content: space-around;
      margin-top: -40px;
      .yes {
        height: 30px;
        width: 80px;
        background-color: #f03636;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin-top: 10px;
      }
      .no {
        height: 30px;
        width: 80px;
        background-color: #333;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin-top: 10px;
      }
    }
  }
}
</style>