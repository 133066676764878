<template>
    <div class="page_one">
        <div class="level_title self_">
            <span class="B2">Level PET</span>
            <span class="content">
                <span>
                    {{ readingP1.questionDetail }}
                </span>
            </span>
        </div>
        <p style="text-align: center;margin: 40px auto"><b>part1</b></p>

        <div class="contain">
            <div class="new_box_1">
                <div v-for="(item, index) in questionListPart1" :key="index">
                    <div class="level_title topic">
                        <span class="B2 topic_B2">{{ index + 1 }}</span>
                        <span class="content topic_con">{{ item.questionText }}</span>
                    </div>
                    <div class="box">
                        <div class="title">
                            <img :src="item.questionUrl" alt="" />
                        </div>
                        <div class="option">
                            <ul>
                                <li class="li_style" @click="handleAnswerSelect(item, index, 'A')"
                                    :class="{ active: answer[index] === 'A' }">
                                    <div class="select_center" style="font-size:20px;">A</div>
                                    <span>{{ item.optionAText }}</span>
                                </li>
                                <li class="li_style" @click="handleAnswerSelect(item, index, 'B')"
                                    :class="{ active: answer[index] === 'B' }">
                                    <div class="select_center" style="font-size:20px;">B</div>
                                    <span>{{ item.optionBText }}</span>
                                </li>
                                <li class="li_style" @click="handleAnswerSelect(item, index, 'C')"
                                    :class="{ active: answer[index] === 'C' }">
                                    <div class="select_center" style="font-size:20px;">C</div>
                                    <span>{{ item.optionCText }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <p style="text-align: center;margin: 40px auto"><b>part2</b></p>
            <div class="new_box">
                <div class="left">
                    <h2>Janine Stuart, young writer</h2>
                    <span>When Janine Stuart was only 13, she wrote a novel called ‘Being Amelia’, which went on to become a bestseller. ‘Being Amelia’ is partly based on Janine’s life. In one interview about her book, Janine said, ‘Because of my father’s job, my family’s moved around the country a lot, so I’ve become good at making new friends. I understand what it’s like to be the only new student in a class, and that helped me to write about how the main character, Amelia, gets on in a new school. Some of the funniest parts of the book focus on Amelia’s home life, particularly her relationship with her older brother. As I’m my parents’ only child, I had to use my imagination to describe that’.</span>
                    <br/><br/>
                    <span>Janine started her novel one summer, to avoid getting bored at home during the holidays when no other teenagers were around. Her mother had always dreamt of becoming a published author herself, and her teachers had always encouraged her to write, so it seemed like a natural way for Janine to pass the time. The year before, she’d entered a short-story competition on a young writers’ website and won first prize. That’s why, when Janine finished ‘Being Amelia’ and sent it to a publishing company, the people who worked there were immediately keen to read it. They loved it, and a year later, Janine’s novel was on sale in bookshops.</span>
                    <br/><br/>
                    <span>One review of ‘Being Amelia’ mentionshow, despite the book having pictures of happy teenagers on the cover, the people in the story actually face some difficult challenges, and this is what really allows readers to believe in them. The reviewer says that although Janine is still very young, and ‘Being Amelia’ isn’t a particularly long novel, she’s clearly shown that she’s a better writer than several other bestselling authors.</span>
                    <br/><br/>
                    <span>Janine’s still getting used to being a famous writer: ‘At first, I hid when people recognised me in the street, but now it’s OK. Although I’d like to give advice to other young writers, they have to find what works for them. For me, I know that all I have to do is hangout with my friends for a while, and I’ll soon have something interesting to write about!’</span>
                    <br /><br />
                </div>
                <div class="right part2">

                    <div class="item" v-for="(item, index) in questionListPart2" :key="item.id">
                        <div class="title">
                            <div>{{ index + 6 }}</div>
                            <span>{{ item.questionText }}</span>
                        </div>
                        <ul >
                            <li @click="handleAnswerSelect(item, index+5, 'A')"
                                :class="{ active: answer[index+5] === 'A' }">
                                <div style="font-size:20px;">A</div>
                                <span>{{ item.optionAText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index+5, 'B')"
                                :class="{ active: answer[index+5] === 'B' }">
                                <div style="font-size:20px;">B</div>
                                <span>{{ item.optionBText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index+5, 'C')"
                                :class="{ active: answer[index+5] === 'C' }">
                                <div style="font-size:20px;">C</div>
                                <span>{{ item.optionCText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index+5, 'D')"
                                :class="{ active: answer[index+5] === 'D' }">
                                <div style="font-size:20px;">D</div>
                                <span>{{ item.optionDText }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <p style="text-align: center;margin: 40px auto"><b>part3</b></p>
            <div class="new_box">
                <div class="left">
               
                    <h2>Climbing Mount Kilimanjaro</h2>
                    <span>Mount Kilimanjaro in Africa is a mountain that lots of people dream of climbing. Every year it &nbsp;(11)_______&nbsp;tens of thousands of tourists and young travellers from all over the world, all wanting to attempt the 5,895-metre climb.</span><br /><br />
                    <span>It actually doesn't &nbsp;(12)_______&nbsp; that much skill to climb it. Healthy people with a &nbsp;(13)_______&nbsp;level of   fitness and a positive attitude have a good chance of getting to the top. The climbers who &nbsp;(14)_______&nbsp;to get to the icy, snowy top of Kilimanjaro can say they have stood on Africa's highest point.</span><br /><br />
                    <span>In order to prevent the visitors from damaging the mountain and its environment, climbers are told theymust &nbsp;(15)_______&nbsp;certain routes and sleep at special camps. However, the mountain cannot be protected from climate change, and experts have &nbsp;(16)_______&nbsp;that the ice and snow at the top of the mountain is    melting, and could disappear in the future.</span><br /><br />
                </div>
                <div class="right">
                   
                    <div class="item" v-for="(item, index) in questionListPart3" :key="item.id">
                        <div class="title">
                            <div>{{ index + 11 }}</div>
                            <span>{{ item.questionText }}</span>
                        </div>
                        <ul>
                            <li @click="handleAnswerSelect(item, index + 10, 'A')"
                                :class="{ active: answer[index + 10] === 'A' }">
                                <div style="font-size:20px;">A</div>
                                <span>{{ item.optionAText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index + 10, 'B')"
                                :class="{ active: answer[index + 10] === 'B' }">
                                <div style="font-size:20px;">B</div>
                                <span>{{ item.optionBText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index + 10, 'C')"
                                :class="{ active: answer[index + 10] === 'C' }">
                                <div style="font-size:20px;">C</div>
                                <span>{{ item.optionCText }}</span>
                            </li>
                            <li @click="handleAnswerSelect(item, index + 10, 'D')"
                                :class="{ active: answer[index + 10] === 'D' }">
                                <div style="font-size:20px;">D</div>
                                <span>{{ item.optionDText }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PaperReading1PET",
    data() {
        return {
            flag: 0,
            article: "",
            text: "",
            textDetile: [],
            articleTitle: "",
            // 试卷答案
            answerList: [],
            answer: {},
            readingP1: {},
            articleTitle4: "",
            article4: "",
            textDetile4: [],
            text4: "",
            questionListPart1: [],
            questionListPart2: [],
            questionListPart3: [],
        }
    },
    mounted() {
        this.getPaperInfo()
    },
    methods: {
        select(i) {
            this.flag = i
        },
        // 获取试卷信息
        getPaperInfo() {
            setTimeout(() => {
                let result = this.paperData.readingP1;
                // 阅读part3
                this.readingP1 = {
                    ...result,
                    questionList: result.questionList
                };
                this.readingP1.questionList.forEach((item, i) => {
                    this.answerList[i] = ''
                })
                this.questionListPart1 = this.readingP1.questionList.slice(0, 5);
                this.questionListPart2 = this.readingP1.questionList.slice(5, 10);
                this.questionListPart3 = this.readingP1.questionList.slice(10, 16);
            }, 1000);
        },
        handleAnswerSelect(item, index, answer) {
            this.activeIndex = index;
            this.$set(this.answer, index, answer);
            // console.log(this.answer)
        }
    },
    watch: {
        // 收集答案
        answer: {

            handler(newVal, oldVal) {

                // console.log(this.answers);
                for (let info in newVal) {
                    if (newVal[info]) {
                        // console.log(newVal[info]);
                        this.answerList[info] = newVal[info]
                    } else {
                        this.answerList[info] = ''
                    }
                }
                // console.log(answerList);
                let answers = [...this.answerList]
                this.$store.dispatch('getAnswerRedOne', answers)
                console.log(answers);

            },
            deep: true

        },
        paperData() {
            this.getPaperInfo()
        }
    },
    computed: {
        ...mapState({
            paperData: (state) => state.home.data,
            level: (state) => state.home.level,
        }),
    }
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;
}

.new_box_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .box {
        height: 250px;
        width: 100%;
        margin: 0 auto;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        display: flex;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: center;
            width: 600px;
            justify-content: center;

            img {
                height: 95%;
                max-width: 600px;
            }
        }

        .option {
            width: 560px;
            height: 100%;
            margin-right: 5px;

            ul {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-around;
                box-sizing: border-box;

                li {
                    display: flex;
                    height: 45px;
                    justify-content: center;

                    //   line-height: 45px;
                    box-sizing: border-box;

                    div {
                        width: 50px;
                        background-color: #333;
                        color: white;
                        text-align: center;
                        box-sizing: border-box;
                    }

                    .select_center {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    span {
                        background-color: #fff;
                        border: 1px solid #000;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        border-left: none;
                        padding-left: 10px;
                    }
                }

                .active {

                    border: 1px solid rgb(240, 54, 54);
                    box-sizing: border-box;

                    div {
                        background-color: rgb(240, 54, 54);
                        box-sizing: border-box;
                    }

                    span {
                        border: none;
                        background-color: #fff2f2;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}

.page_one {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
}

.self_ {
    margin-bottom: 30px;
}

.level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;

    span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .B2 {
        width: 10%;
        line-height: 50px;
        background-color: rgb(240, 54, 54);
    }

    .topic_B2 {
        background-color: rgb(51, 51, 51);
        font-size: 20px;
    }

    .content {
        padding: 0 30px;
        width: 90%;
        background-color: rgb(51, 51, 51);
        display: flex;
        align-items: center;

        box-sizing: border-box;

        span {
            display: flex;
            align-items: center;
            font-size: 14px;
            text-align: start;
        }
    }

    .topic_con {
        background-color: rgb(166, 166, 166);
    }
}

.contain {
    .new_box {
        display: flex;
        justify-content: space-between;
    }


    .left {
        width: 50%;
        box-sizing: border-box;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        min-height: 800px;
        // overflow: scroll;

        h2 {
            text-align: center;
            font-size: 14px;
            margin: 20px 0;
        }

        span {
            font-size: 14px;
            line-height: 24px;
        }
    }
    
    .right {
        width: 47%;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        padding: 10px;
        box-sizing: border-box;
        // overflow: scroll;
        min-height: 800px;
        
        .item {
            width: 95%;
            height: 450px;
            margin-top: 20px;

            .title {
                display: flex;

                div {
                    width: 60px;
                    height: 50px;
                    background-color: #333;
                    color: #fff;
                    font-size: 18px;
                    line-height: 50px;
                    text-align: center;
                }

                span {
                    flex: 1;
                    background-color: #a6a6a6;
                    height: 50px;
                    color: #fff;
                    box-sizing: border-box;
                    padding: 5px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                }
            }
           
            ul {
                height: 400px;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                gap: 20px;

                li {
                    display: flex;
                    width: 100%;

                    height: 70px;
                    box-sizing: border-box;
                    padding-left: 30px;

                    div {
                        width: 50px;
                        background-color: #333;
                        color: #fff;
                        font-size: 18px;
                        line-height: 70px;
                        text-align: center;
                    }

                    span {
                        flex: 1;
                        border: 1px solid #000;
                        border-left: none;
                        box-sizing: border-box;
                        padding-left: 10px;

                        font-size: 14px;
                        display: flex;
                        align-items: center;
                    }
                }

                .active {
                    box-sizing: border-box;

                    div {
                        background-color: rgb(240, 54, 54);

                    }

                    span {
                        background-color: #fff2f2;
                        border: 1px solid rgb(240, 54, 54);
                    }
                }
            }
          
        }
    }
}
</style>