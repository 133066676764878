<template>
    <div class="page_one">
      <div class="level_title self_">
        <span class="B2">Level {{ level }}</span>
        <span class="content">
          <span>
            {{ titleList.questionDetail }}
          </span>
        </span>
      </div>
      <div class="imglist">
        <ul>
            <li><img src="https://97english-1314636513.cos.ap-nanjing.myqcloud.com/PET/TEST1/images/PET-Test1-RP2-1.jpg" alt=""><span>17</span></li>
            <li><img src="https://97english-1314636513.cos.ap-nanjing.myqcloud.com/PET/TEST1/images/PET-Test1-RP2-2.jpg" alt=""><span>18</span></li>
            <li><img src="https://97english-1314636513.cos.ap-nanjing.myqcloud.com/PET/TEST1/images/PET-Test1-RP2-3.jpg" alt=""><span>19</span></li>
            <li><img src="https://97english-1314636513.cos.ap-nanjing.myqcloud.com/PET/TEST1/images/PET-Test1-RP2-4.jpg" alt=""><span>20</span></li>
            <li><img src="https://97english-1314636513.cos.ap-nanjing.myqcloud.com/PET/TEST1/images/PET-Test1-RP2-5.jpg" alt=""><span>21</span></li>
        </ul>
      </div>
      <div class="contain">
        <div class="left">
          <draggable
            v-model="myArray"
            :group="groupA"
            animation="300"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            :limit="1"
          >
            <transition-group>
              <div
                class="item"
                v-for="item in myArray"
                :key="item.index"
                :class="flagList[item.index] == '1' ? 'active' : ''"
              >
                <div class="header">{{ item.id }}</div>
                <div class="body"><img :src="item.name" alt="" /></div>
                <div class="bodyT">
                  <span v-html="item.text"></span>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="right">
          <div class="item">
            <div class="title">
              <div class="leftOne">17</div>
              <div class="rightOne" v-html="titleList.questionList[0]"></div>
            </div>
            <draggable
              v-model="arr2"
              :group="groupB"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group>
                <div class="item" v-for="(item, i) in arr2" :key="i">
                  <div class="answer" v-if="item">
                    {{ item.id
                    }}<img src="@/assets/cancel.png" alt="" @click="arr2.pop()" />
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="item">
            <div class="title">
              <div class="leftOne">18</div>
              <div class="rightOne" v-html="titleList.questionList[1]"></div>
            </div>
            <draggable
              v-model="arr3"
              :group="groupB"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group>
                <div class="item" v-for="(item, i) in arr3" :key="i">
                  <div class="answer" v-if="item">
                    {{ item.id
                    }}<img src="@/assets/cancel.png" alt="" @click="arr3.pop()" />
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="item">
            <div class="title">
              <div class="leftOne">19</div>
              <div class="rightOne" v-html="titleList.questionList[2]"></div>
            </div>
            <draggable
              v-model="arr4"
              :group="groupB"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group>
                <div class="item" v-for="(item, i) in arr4" :key="i">
                  <div class="answer" v-if="item">
                    {{ item.id
                    }}<img src="@/assets/cancel.png" alt="" @click="arr4.pop()" />
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="item">
            <div class="title">
              <div class="leftOne">20</div>
              <div class="rightOne" v-html="titleList.questionList[3]"></div>
            </div>
            <draggable
              v-model="arr5"
              :group="groupB"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group>
                <div class="item" v-for="(item, i) in arr5" :key="i">
                  <div class="answer" v-if="item">
                    {{ item.id
                    }}<img src="@/assets/cancel.png" alt="" @click="arr5.pop()" />
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="item">
            <div class="title">
              <div class="leftOne">21</div>
              <div class="rightOne" v-html="titleList.questionList[4]"></div>
            </div>
            <draggable
              v-model="arr6"
              :group="groupB"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group>
                <div class="item" v-for="(item, i) in arr6" :key="i">
                  <div class="answer" v-if="item">
                    {{ item.id
                    }}<img src="@/assets/cancel.png" alt="" @click="arr6.pop()" />
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import draggable from "vuedraggable";
  export default {
    name:"PETRead2",
    //注册draggable组件
    components: {
      draggable,
    },
    data() {
      return {
        drag: false,
        message: "",
        groupA: {
          name: "itxst",
          put: false, //可以拖入
          pull: "clone",
        },
        groupB: {
          name: "itxst",
          pull: false, //B组拖拽时克隆到A组
          put: true,
        },
  
        //定义要被拖拽对象的数组
        myArray: [
          {
            id: "A",
            name: "",
            index: 0,
          },
          {
            id: "B",
            name: "",
            index: 1,
          },
          {
            id: "C",
            name: "",
            index: 2,
          },
          {
            id: "D",
            name: "",
            index: 3,
          },
          {
            id: "E",
            name: "",
            index: 4,
          },
          {
            id: "F",
            name: "",
            index: 5,
          },
          {
            id: "G",
            name: "",
            index: 6,
          },
          {
            id: "H",
            name: "",
            index: 7,
          },

        ],
        arr2: [],
        arr3: [],
        arr4: [],
        arr5: [],
        arr6: [],
        arr7: [],
        arr8: [],
        flagList: ["", "", "", "", "","","",""],
        titleList: {
          haveOptionsImg: "",
          haveOptionsText: "",
          haveQuestionText: "",
          id: "",
          optionAText: "",
          optionAUrl: "",
          optionBText: "",
          optionBUrl: "",
          optionCText: "",
          optionCUrl: "",
          optionsNumber: "",
          questionText: "",
          questionList: [],
        },
        // 收集答案
        answerList: ["", "", "", "", ""],
      };
    },
    mounted() {
      this.getPaperInfo();
    },
    methods: {
      //开始拖拽事件
      onStart() {
        this.drag = true;
        return true;
      },
      //拖拽结束事件
      onEnd() {
        this.drag = false;
      },
      // 获取试卷信息
      getPaperInfo() {
        setTimeout(() => {
          let result = this.paperData;
          this.titleList = result.readingP2;
          // console.log(this.titleList.questionList);
          this.myArray.forEach((item, i) => {
            item.name = this.titleList.optionList[i].optionUrl;
            item.text = this.titleList.optionList[i].optionText;
          });
        }, 1000);
      },
    },
    watch: {
      arr2() {
        if (this.arr2.length) {
          this.answerList[0] = this.arr2[0].id || "";
        } else {
          this.answerList[0] = "";
        }
        if (this.arr2.length >= 2) {
          this.arr2.shift();
        }
      },
      arr3() {
        if (this.arr3.length) {
          this.answerList[1] = this.arr3[0].id || "";
        } else {
          this.answerList[1] = "";
        }
  
        if (this.arr3.length >= 2) {
          this.arr3.shift();
        }
      },
      arr4() {
        if (this.arr4.length) {
          this.answerList[2] = this.arr4[0].id || "";
        } else {
          this.answerList[2] = "";
        }
        if (this.arr4.length >= 2) {
          this.arr4.shift();
        }
      },
      arr5() {
        if (this.arr5.length) {
          this.answerList[3] = this.arr5[0].id || "";
        } else {
          this.answerList[3] = "";
        }
  
        if (this.arr5.length >= 2) {
          this.arr5.shift();
        }
      },
      arr6() {
        if (this.arr6.length) {
          this.answerList[4] = this.arr6[0].id || "";
        } else {
          this.answerList[4] = "";
        }
  
        if (this.arr6.length >= 2) {
          this.arr6.shift();
        }
      },
      totalArr() {
        let anwser = [];
        this.answerList.forEach((item, i) => {
          anwser[i] = item;
        });
        this.$store.dispatch("getAnswerRedTwo", anwser);
        
        if (this.totalArr.some((item)=>item.id == 'A')) {
          this.flagList[0] = 1;
        } else {
          this.flagList[0] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'B')) {
          this.flagList[1] = 1;
        } else {
          this.flagList[1] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'C')) {
          this.flagList[2] = 1;
        } else {
          this.flagList[2] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'D')) {
          this.flagList[3] = 1;
        } else {
          this.flagList[3] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'E')) {
          this.flagList[4] = 1;
        } else {
          this.flagList[4] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'F')) {
          this.flagList[5] = 1;
        } else {
          this.flagList[5] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'G')) {
          this.flagList[6] = 1;
        } else {
          this.flagList[6] = 0;
        }
        if (this.totalArr.some((item)=>item.id == 'H')) {
          this.flagList[7] = 1;
        } else {
          this.flagList[7] = 0;
        }
      },
    },
    computed: {
      totalArr() {
        return [
          ...this.arr2,
          ...this.arr3,
          ...this.arr4,
          ...this.arr5,
          ...this.arr6,
          ...this.arr7,
          ...this.arr8,
        ];
      },
      ...mapState({
        paperData: (state) => state.home.data,
        level: (state) => state.home.level,
      }),
    },
  };
  </script>
  
  <style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .page_one {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
  }
  
  .self_ {
    margin-bottom: 30px;
  }
  .level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;
  
    span {
      height: 50px;
      color: snow;
      text-align: center;
    }
  
    .B2 {
      width: 10%;
      line-height: 50px;
      background-color: rgb(240, 54, 54);
    }
  
    .topic_B2 {
      background-color: rgb(51, 51, 51);
      font-size: 20px;
    }
  
    .content {
      padding: 0 30px;
      width: 90%;
      background-color: rgb(51, 51, 51);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
      }
    }
    .topic_con {
      background-color: rgb(166, 166, 166);
    }
   
  }
  .imglist{
    padding: 0 30px;
        ul{
            list-style: none;
            display: flex;
        justify-content: space-between;
            li{
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 120px;
                    margin-bottom: 10px;
                }
                span{
                    font-weight: 700;
                }
            }
        }
    }
  .contain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      width: 55%;
      height: 600px;
      box-sizing: border-box;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      overflow: scroll;
      line-height: 24px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
          height: 50px;
          width: 100%;
          background-color: #333;
          color: #fff;
          line-height: 50px;
          font-weight: 900;
          font-size: 24px;
          text-align: center;
        }
        .body {
          width: 80%;
          box-sizing: border-box;
          padding: 10px;
          img {
            width: 100%;
          }
        }
        .bodyT {
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          margin-top: -45px;
          span {
            width: 100%;
            display: block;
  
            text-indent: 30px;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      .active {
        box-sizing: border-box;
        .header {
          background-color: rgb(240, 54, 54);
        }
      }
    }
    .right {
      width: 43%;
      height: 600px;
      box-sizing: border-box;
      padding: 10px;
      overflow: scroll;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      span {
        display: inline-block;
        height: 33px;
        width: 98%;
        border: 1px solid #000;
        vertical-align: bottom;
        margin: 0 5px;
        overflow: hidden;
        text-align: center;
        line-height: 33px;
        box-sizing: border-box;
        position: relative;
        img {
          width: 12px;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        .title {
          height: 120px;
          width: 100%;
  
          display: flex;
          .leftOne {
            width: 60px;
            height: 120px;
            background-color: #333;
            color: #fff;
            text-align: center;
            line-height: 120px;
            font-size: 20px;
          }
          .rightOne {
            flex: 1;
            height: 120px;
            background-color: #a6a6a6;
            color: #fff;
            padding-left: 5px;
            list-style: none;
            line-height: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
        }
      }
    }
  }
  </style>